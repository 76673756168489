
import service from '../request'
import { baseServerName } from './config'

import axios from "axios";
const CancelToken = axios.CancelToken;
export let canceAxios;

// 首页-获取广告图
export const getADList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getADList`,
    data: data
  })
}

// 首页-获取推荐房间列表
export const getSuggestRoomList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/suggest`,
    data: data
  })
}

// 首页-获取全部房间列表
export const getRoomList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/getRoomList`,
    data: data
  })
}

// 首页-获取房间类目列表
export const getRoomType = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getRoomType`,
    data: data
  })
}

// 首页-根据类目查询房间列表
export const getRoomTypeList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/getRoomTypeList`,
    data: data
  })
}

// 首页-获取快速进房列表
export const getQuickRoomList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getQuickRoomList`,
    data: data
  })
}

// 首页-获取陪玩推荐列表
export const getSuggestLiveList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/live/getSuggestLiveList`,
    data: data
  })
}

// 首页-获取陪玩技能分类列表
export const getClassSkillList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/live/getClassSkillList`,
    data: data
  })
}

// 首页-获取陪玩技能分类对应陪玩列表
export const getSkillLiveList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/live/getSkillLiveList`,
    data: data
  })
}

// 首页-搜索房间、陪玩
export const search = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/live/search`,
    data: data
  })
}

// 获取收藏房间列表
export const getCollectRoomList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/collect`,
    data: data
  })
}

// 首页-获取财富榜列表
export const getSpendList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getSpendList`,
    data: data
  })
}

// 首页-获取魅力榜列表
export const getReceiveList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getReceiveList`,
    data: data
  })
}

// 房间-获取房间贡献榜列表
export const getContribution = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getContribution`,
    data: data
  })
}

// 首页-获取飘屏数据
export const getFlyList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/getFlyList`,
    data: data
  })
}

// 获取商城列表
export const getProductList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/product/getProductList`,
    data: data
  })
}

// 获取商品价格列表
export const getPriceList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/product/getPriceList`,
    data: data
  })
}

// 获取购买商品
export const commodity = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/commodity`,
    data: data
  })
}

// 特权列表
export const getLeveList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getLeveList`,
    data: data
  })
}

// 大神-获取全部陪玩列表
export const getAllLiveList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/live/getAllLiveList`,
    data: data
  })
}

// 消息-获取新消息数量（官方、互动、系统）
export const getNewMessageNum = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/message/getNewMessageNum`,
    data: data
  })
}

// 消息-官方消息
export const getMessageList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/message/getMessageList`,
    data: data
  })
}

// 消息-互动消息
export const getManMessageList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/message/getManMessageList`,
    data: data
  })
}

// 消息-系统消息
export const getSysMessageList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/message/getSysMessageList`,
    data: data
  })
}

/** 用户模块 -- start */

// 发送短信
export const sendSms = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/sendSms`,
    data: data
  })
}

// 登录或注册-短信验证码方式
export const smsLogin = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/smsLogin`,
    data: data
  })
}

// 登录或注册-微信登录
export const wechatLogin = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/wechatLogin`,
    data: data
  })
}

// 登录或注册-绑定手机号
export const bindLogin = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/bindLogin`,
    data: data
  })
}

// 登录或注册-实名认证
export const setCardId = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/setCardId`,
    data: data
  })
}

// 刷新用户基础信息
export const refresh = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/refresh`,
    data: data
  })
}

// 获取指定uid用户的个人基本信息
export const getUserInfo = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getUserInfo`,
    data: data
  })
}

// 获取指定uid用户当前所在房间信息
export const getUserRoom = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getUserRoom`,
    data: data
  })
}

// 获取指定uid用户的个人cp
export const getUserCP = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getUserCP`,
    data: data
  })
}

// 获取指定uid用户的个人技能
export const getUserSkill = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getUserSkill`,
    data: data
  })
}

// 获取礼物分类列表
export const getCategoryList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getCategoryList`,
    data: data
  })
}

// 获取指定uid用户的个人礼物
export const getUserGift = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getUserGift`,
    data: data
  })
}

// 获取指定uid用户的个人勋章
export const getUserLevel = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getUserLevel`,
    data: data
  })
}

// 关注、取关
export const doFans = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/doFans`,
    data: data
  })
}

// 获取指定uid用户的个人动态
export const getCommunityList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/community/getCommunityList`,
    data: data
  })
}

// 点赞动态
export const addLike = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/addLike`,
    data: data
  })
}

// 获取动态评论列表
export const getCommentList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/getCommentList`,
    data: data
  })
}

// 举报
export const addComplaint = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/addComplaint`,
    data: data
  })
}

// 拉黑
export const addBlack = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/addBlack`,
    data: data
  })
}

// 修改用户信息
export const editUserInfo = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/editUserInfo`,
    data: data
  })
}

// 设置-添加邀请人
export const addInvite = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/addInvite`,
    data: data
  })
}

// 获取当前用户余额
export const getBalance = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/getBalance`,
    data: data
  })
}

// 获取充值列表
export const getPayList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/getPayList`,
    data: data
  })
}

// 获取支付渠道
export const getRechargeChannel = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/getRechargeChannel`,
    data: data
  })
}

// 获取微信充值二维码
export const getWxCodeUrl = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/getWxCodeUrl`,
    data: data
  })
}

// 支付宝充值
export const aliPcPay = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/aliPcPay`,
    data: data
  })
}

// 获取充值支付状态
export const getPayStatus = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/getPayStatus`,
    data: data,
    cancelToken: new CancelToken(function executor(c) {
      canceAxios = c;
    })
  })
}

// 获取陪玩列表
export const getFansLiveList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getFansLiveList`,
    data: data
  })
}

// 获取我的粉丝列表
export const getMyFansList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getMyFansList`,
    data: data
  })
}

// 获取黑名单列表
export const getBlackList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getBlackList`,
    data: data
  })
}

// 获取随心钻流水列表
export const getDiamondList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getDiamondList`,
    data: data
  })
}

// 获取随心币流水列表
export const getCoinList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getCoinList`,
    data: data
  })
}

// 获取随心币流水详情
export const getCoinDetail = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getCoinDetail`,
    data: data
  })
}

// 随心币兑换随心钻
export const exchangeCoinToMe = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/exchangeCoinToMe`,
    data: data
  })
}

// 获取我的装扮列表
export const getMyProductList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getProductList`,
    data: data
  })
}

// 使用装扮
export const useProduct = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/useProduct`,
    data: data
  })
}

// 获取我的冠名礼物
export const getNamingList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/getNamingList`,
    data: data
  })
}

// 使用冠名
export const useNaming = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/useNaming`,
    data: data
  })
}

// 上传文件
export const uploadFile = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/uploadFile`,
    data: data
  })
}

/** 用户模块 --   end  */


/** 直播间-融云 --   start  */
// 融云-创建聊天房间
export const createRoomRong = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/rong/createRoom`,
    data: data
  })
}
// 融云-查询聊天室成员
export const getRoomUserRong = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/rong/getRoomUser`,
    data: data
  })
}
/** 直播间-融云 --    end   */

/** 直播间 --  start   */
// 直播间-获取直播间公告
export const getProfile = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getProfile`,
    data: data
  })
}

// 直播间-获取当前用户在房间身份+
export const getRoomRole = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getRoomRole`,
    data: data
  })
}

// 直播间-获取当前用户在房间钻石收益
export const getRoomDiamond = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getRoomDiamond`,
    data: data
  })
}

// 直播间-清空房间麦位上用户的随心值
export const editRoomDiamond = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/editRoomDiamond`,
    data: data
  })
}

// 直播间-获取当前房间在线人数
export const getRoomUser = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/getRoomUser`,
    data: data
  })
}

// 直播间-维护房间麦位信息
export const roomSeat = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/roomSeat`,
    data: data
  })
}

// 直播间-申请上麦
export const addMic = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/addMic`,
    data: data
  })
}

// 直播间-获取上麦申请列表
export const getMicList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getMicList`,
    data: data
  })
}

// 直播间-同意/拒绝上麦申请
export const doMic = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/doMic`,
    data: data
  })
}

// 直播间-清空上麦申请
export const delMic = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/RoomMicServiceImpl.delMic`,
    data: data
  })
}

// 直播间-房间/音乐：收藏\取消
export const doCollect = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/doCollect`,
    data: data
  })
}

// 直播间-获取礼物tab配置
export const getGiftgetConfig = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/getConfig`,
    data: data
  })
}
// 直播间-获取直送礼物列表
export const getGiftListByType4 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getGiftListByType4`,
    data: data
  })
}

// 直播间-获取我的背包礼物列表
export const getBagList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getBagList`,
    data: data
  })
}

// 直播间-获取礼包列表
export const getGiftListByType1 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getGiftListByType1`,
    data: data
  })
}

// 直播间-获取宝箱、魔法师列表
export const getGiftListByType23 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getGiftListByType23`,
    data: data
  })
}

// 直播间-送礼物-直送
export const giveGift = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/gift`,
    data: data
  })
}

// 直播间-送礼物-背包
export const giveBagGift = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/bagGift`,
    data: data
  })
}

// 直播间-送礼物-礼包
export const givePackGift = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/pack`,
    data: data
  })
}

// 直播间-送礼物-开宝箱
export const giveBoxGift = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/openBox`,
    data: data
  })
}

// 直播间-送礼物-开魔法师
export const openMagician = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/openMagician`,
    data: data
  })
}

// 直播间-获取魔法师抽取次数
export const getMagCount = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getMagCount`,
    data: data
  })
}

// 直播间-获取二级宝箱抽取次数
export const getActivityLevel2Count = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getActivityLevel2Count`,
    data: data
  })
}
// 直播间-开启二级宝箱
export const openActivityLevel2 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/openActivityLevel2`,
    data: data
  })
}
// 直播间-获取二级宝箱开启记录
export const getActivityLevel2Record = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getActivityLevel2Record`,
    data: data
  })
}
// 直播间-获取二级宝箱阶段奖池
export const getActivityLevel2GiftList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getActivityLevel2GiftList`,
    data: data
  })
}
// 直播间-获取二级宝箱规则
export const getActivityLevel2Rule = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getActivityLevel2Rule`,
    data: data
  })
}

// 直播间-送礼物-幸运榜
export const getLuckList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getLuckListV2`,
    data: data
  })
}

// 直播间-送礼物-玩法说明
export const getConfigid7 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getConfigid7`,
    data: data
  })
}

// 直播间-表情-获取自定义表情列表
export const getExpressList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getExpressList`,
    data: data
  })
}

// 直播间-表情-添加自定义表情
export const addExpress = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/addExpress`,
    data: data
  })
}

// 直播间-表情-删除自定义表情
export const delExpress = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/delExpress`,
    data: data
  })
}

// 直播间-队长任务
export const getTaskList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getTaskList`,
    data: data
  })
}

// 直播间-队长收益/升级
export const getLiveLevel = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getLiveLevel`,
    data: data
  })
}

// 直播间-禁言/踢人
export const doStop = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/doStop`,
    data: data
  })
}

// 直播间-队长新增聊天任务
export const addTaskIm = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/addTaskIm`,
    data: data
  })
}

// 直播间-设置-功能开关
export const setFunction = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/my/setFunction`,
    data: data
  })
}


/** 直播间 --   end   */

/** 直播间-声网 --  start   */
// 直播间-声网-获取token
export const getSWToken = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/getToken`,
    data: data
  })
}
// 直播间-声网-获取token
export const getSWInfo = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getInfo`,
    data: data
  })
}
// 直播间-声网-获取token（包含密码房间校验）
export const getSWInfoV2 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getInfo_v2`,
    data: data
  })
}
// 直播间-声网-获取车队token
export const getFleetInfo = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getFleetInfo`,
    data: data
  })
}
/** 直播间-声网 --   end   */

// 获取app下载地址路径
export const getVersionLatest = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/getVersionLatest`,
    data: data
  })
}

/** 活动 --  start  */
// 充值活动—获取充值活动详情
export const getPayItemList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getPayItemList`,
    data: data
  })
}
// 充值活动—获取充值活动详情(新)
export const getPayItemListV3 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getPayItemListV3`,
    data: data
  })
}
// 充值活动-领取
export const receive = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/receive`,
    data: data
  })
}
// 厅活动流水排行榜（双十一）
export const getRankRoomList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getRankRoomList`,
    data: data
  })
}

// 厅活动流水排行榜（双十二）
export const getRankRoomListForDouble12 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getRankRoomListForDouble12`,
    data: data
  })
}

// 推荐老板私聊状态更新
export const mengXinClick = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activityLive/click`,
    data: data
  })
}

// 圣诞节活动礼物列表
export const getGiftListForChristmas = (data) => {
  return service.request({
    method: 'get',
    url: `/${baseServerName}/activity/getGiftListForChristmas`,
    data: data
  })
}
// 领取圣诞节活动礼物
export const receiveGiftForChristmas = (data) => {
  return service.request({
    method: 'get',
    url: `/${baseServerName}/activity/receiveGiftForChristmas`,
    data: data
  })
}

// 元旦节活动信息
export const getGiftInfoForNewYear = (data) => {
  return service.request({
    method: 'get',
    url: `/${baseServerName}/activity/getGiftInfoForNewYear`,
    data: data
  })
}
// 领取元旦节活动礼物
export const receiveGiftForNewYear = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/receiveGiftForNewYear`,
    data: data
  })
}

// 一月活动-获取礼物列表
export const getGiftListForJanuary = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getGiftListForJanuary`,
    data: data
  })
}
// 一月活动-礼物列表刷新
export const refreshGiftListForJanuary = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/refreshGiftListForJanuary`,
    data: data
  })
}
// 一月活动-礼物购买
export const buyGiftForJanuary = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/buyGiftForJanuary`,
    data: data
  })
}
// 一月活动-礼物购买记录
export const buyGiftRecordForJanuary = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/buyGiftRecordForJanuary`,
    data: data
  })
}
// 一月活动—任务达成列表信息
export const taskForJanuary = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/taskForJanuary`,
    data: data
  })
}
// 情人节活动
export const rankOfValentine = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/rankOfValentine`,
    data: data
  })
}
// 情人节活动-赠送的装扮
export const giftOfValentine = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/giftOfValentine`,
    data: data
  })
}
// 春节活动-拼出幸福年
export const getSpringData = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getSpringData`,
    data: data
  })
}
// 春节活动-解锁拼图
export const springImgUnlock = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/springImgUnlock`,
    data: data
  })
}
// 春节活动-福运迎春排行榜
export const springRankList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/springRankList`,
    data: data
  })
}
// 春节活动-福运迎春排行榜赠送的礼物
export const springGiftForRank = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/springGiftForRank`,
    data: data
  })
}
// 女神节-排行榜
export const rankOfWomenDay = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/rankOfWomenDay`,
    data: data
  })
}
// 龙年荣耀排行榜
export const rankOfRoomContribution = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/rankOfRoomContribution`,
    data: data
  })
}

// 龙年限定宝箱（塔罗牌）-获取宝箱信息
export const getGiftListByType6 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getGiftListByType6`,
    data: data
  })
}
// 龙年限定宝箱（塔罗牌）-开启龙年宝箱
export const openBox = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/buy/openBox`,
    data: data
  })
}
// 龙年限定宝箱（塔罗牌）-获取装扮兑换列表
export const getDragonBoxExchangeList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/getDragonBoxExchangeList`,
    data: data
  })
}
// 龙年限定宝箱（塔罗牌）-装扮兑换
export const dragonBoxExchange = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/dragonBoxExchange`,
    data: data
  })
}
// 龙年限定宝箱（塔罗牌）-获取开箱记录
export const getOpenRecord = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/room/getOpenRecord`,
    data: data
  })
}
// 龙年限定宝箱（塔罗牌）-获取积分排行榜
export const rankOfDragonBox = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/rankOfDragonBox`,
    data: data
  })
}

// 告白墙-获取告白墙列表
export const getLiveUserConfessionList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/confession/getLiveUserConfessionList`,
    data: data
  })
}
// 告白墙-获取告白卡片列表
export const getConfessionStyleList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/confession/getConfessionStyleList`,
    data: data
  })
}
// 告白墙-发起告白
export const confessionBuy = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/confession/buy`,
    data: data
  })
}


// 七夕活动 - 获取活动数据
export const getQiXiData = (data) => {
  return service.request({
    method: 'POST',
    url: `/${baseServerName}/activity/getQiXiData`,
    data: data
  })
}

// 七夕活动 - 转盘抽取
export const qiXiRoulette = (data) => {
  return service.request({
    method: 'POST',
    url: `/${baseServerName}/activity/qiXiRoulette`,
    data: data
  })
}

// 七夕活动 - 积分排行榜
export const getRankOfQiXi = (data) => {
  return service.request({
    method: 'POST',
    url: `/${baseServerName}/activity/rankOfQiXi`,
    data: data
  })
}

// 七夕活动 - 获取题目
export const getQiXiQuestion = (data) => {
  return service.request({
    method: 'POST',
    url: `/${baseServerName}/activity/getQiXiQuestion`,
    data: data
  })
}

// 七夕活动 - 回答题目
export const answerQiXiQuestion = (data) => {
  return service.request({
    method: 'POST',
    url: `/${baseServerName}/activity/answerQiXiQuestion`,
    data: data,
    custom: {
      showError: false,
    },
  })
}

// 202501排行榜
export const rankOf202501 = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/rankOf202501`,
    data: data
  })
}

// 202502情人节
export const valentine2025Data = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/valentine2025Data`,
    data: data
  })
}

export const valentine2025ExchangeRecords = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/valentine2025ExchangeRecords`,
    data: data
  })
}

export const valentine2025Exchange = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/activity/valentine2025Exchange`,
    data: data
  })
}
/** 活动 --   end   */

/** 车队 --  start  */
// 车队 - 车队异常退出接口
export const abnormalExit = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/abnormalExit`,
    data: data
  })
}
// 车队 - 查询所在车队信息以及车队用户
export const getFleetUserList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/getUserList`,
    data: data
  })
}
// 车队 - 获取房间车队列表
export const getFleetList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/list`,
    data: data
  })
}
// 车队 - 获取车队背景图列表
export const getFleetBackList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/getFleetBackList`,
    data: data
  })
}
// 车队 - 获取车队标签列表
export const getFleetClassList = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/getFleetClassList`,
    data: data
  })
}
// 车队 - 创建车队
export const fleetCreate = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/create`,
    data: data
  })
}
// 车队 - 解散车队
export const fleetDisband = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/disband`,
    data: data
  })
}
// 车队 - 开车
export const fleetStart = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/start`,
    data: data
  })
}
// 车队 - 加入车队
export const fleetJion = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/join`,
    data: data
  })
}
// 车队 - 踢出车队
export const fleetRemove = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/remove`,
    data: data
  })
}
// 车队 - 离开车队
export const fleetLeave = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/leave`,
    data: data
  })
}

// 车队 - 获取当前用户在车队钻石
export const getFleetDiamond = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/getFleetDiamond`,
    data: data
  })
}
// 车队 - 清零车队钻石
export const editFleetDiamond = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/editFleetDiamond`,
    data: data
  })
}
// 车队 - 车队踢人/禁言
export const doFleetStop = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/doFleetStop`,
    data: data
  })
}
// 车队 - 查看某人是否在车队踢人/禁言
export const getFleetStop = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/roomFleet/getFleetStop`,
    data: data
  })
}
// 车队 - 获取车队的声网token
export const getRoomFleetToken = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/getRoomFleetToken`,
    data: data
  })
}
// 车队 - 获取当前车队在线人数
export const getRoomFleetUser = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/agora/getRoomFleetUser`,
    data: data
  })
}
/** 车队 --   end   */
/** 公共 --  start  */
// 前端埋点
export const save = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/userAction/save`,
    data: data
  })
}
// 查询是否登录
export const isLogin = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/user/isLogin`,
    data: data
  })
}
// 获取聊天过滤关键词
export const getConfigWord = (data) => {
  return service.request({
    method: 'post',
    url: `/${baseServerName}/common/getConfigWord`,
    data: data
  })
}
/** 公共 --   end   */
