import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

	{
		path: '/',
		name: 'Index',
		component: () => import('../views/index.vue'),
		meta: {
			keepAlive: true,
			showHeader: true,
		}
	},
	{
		path: '/hall',
		name: 'Hall',
		component: () => import('../views/hall.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	},
	{
		path: '/god',
		name: 'God',
		component: () => import('../views/god.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('../views/searchList.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	},
	{
		path: '/shoppingMall',
		name: 'ShoppingMall',
		component: () => import('../views/shoppingMall.vue'),
		meta: {
			keepAlive: true,
			showHeader: true,
		}
	},
	{
		path: '/recharge',
		name: 'Recharge',
		component: () => import('../views/recharge.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	},
	{
		path: '/homePage/:uid',
		name: 'HomePage',
		component: () => import('../views/homePage.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	}, {
		path: '/room/:roomId/:classOneId',
		name: 'Room',
		component: () => import('../views/room.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	}, {
		path: '/fleetRoom/:roomId/:classOneId/:roomFleetId',
		name: 'FleetRoom',
		component: () => import('../views/fleetRoom.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	}, {
		path: '/setup/:menuTabIndex/:menuIndex',
		name: 'setup',
		component: () => import('../views/setup/index.vue'),
		meta: {
			keepAlive: true,
			showHeader: true,
		}
	}, {
		path: '/richView',
		name: 'richView',
		component: () => import('../views/richView.vue'),
		meta: {
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/agreeView',
		name: 'agreeView',
		component: () => import('../views/agreeView.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	}, {
		path: '/privacyPolicy',
		name: 'privacyPolicy',
		component: () => import('../views/privacyPolicy.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	}, {
		path: '/about',
		name: 'about',
		component: () => import('../views/about.vue'),
		meta: {
			keepAlive: false,
			showHeader: true,
		}
	}, {
		path: '/dlApp',
		name: 'dlApp',
		component: () => import('../views/dlApp.vue'),
		meta: {
			title: '随心语音官方下载',
			keepAlive: false,
			showHeader: false,
		}
	},
	{
		path: '/error',
		name: 'Error',
		component: () => import('../components/Error.vue')
	}, {
		path: '/activity/:id',
		name: 'activity',
		component: () => import('../views/activity/index.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202311/:id',
		name: 'activity_2023_11',
		component: () => import('../views/activity/activity_2023_11.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20231111/',
		name: 'activity_2023_1111',
		component: () => import('../views/activity/activity_2023_11_11.vue'),
		meta: {
			title: '疯狂双十一',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202312/:id',
		name: 'activity_2023_12',
		component: () => import('../views/activity/activity_2023_12.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20231212/',
		name: 'activity_2023_1212',
		component: () => import('../views/activity/activity_2023_12_12.vue'),
		meta: {
			title: '双十二寻找爱悦之音',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20231225/',
		name: 'activity_2023_1225',
		component: () => import('../views/activity/activity_2023_12_25.vue'),
		meta: {
			title: '诞愿此日 邂逅奇缘',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20240101/',
		name: 'activity_2024_0101',
		component: () => import('../views/activity/activity_2024_01_01.vue'),
		meta: {
			title: '2024元旦跨年派对',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202401/:id',
		name: 'activity_2024_01',
		component: () => import('../views/activity/activity_2024_01.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202402/:id',
		name: 'activity_2024_02',
		component: () => import('../views/activity/activity_2024_02.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20240122/',
		name: 'activity_2024_0122',
		component: () => import('../views/activity/activity_2024_01_22.vue'),
		meta: {
			title: '浪漫之约 随心所愿',
			keepAlive: false,
			showHeader: false,
		}
	},
	{
		path: '/activity20240214/',
		name: 'activity_2024_0214',
		component: () => import('../views/activity/activity_2024_02_14.vue'),
		meta: {
			title: '爱就在一起',
			keepAlive: false,
			showHeader: false,
		}
	},
	{
		path: '/activity20240209/',
		name: 'activity_2024_0209',
		component: () => import('../views/activity/activity_2024_02_09.vue'),
		meta: {
			title: '龙吟新春 佳节之音',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202403/:id',
		name: 'activity_2024_03',
		component: () => import('../views/activity/activity_2024_03.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20240308/',
		name: 'activity_2024_0308',
		component: () => import('../views/activity/activity_2024_03_08.vue'),
		meta: {
			title: '最美女神',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity2024/honour',
		name: 'activity_2024_honour',
		component: () => import('../views/activity/activity_2024_honour.vue'),
		meta: {
			title: '2024随心荣耀榜',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202404/:id',
		name: 'activity_2024_04',
		component: () => import('../views/activity/activity_2024_04.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202405/:id',
		name: 'activity_2024_05',
		component: () => import('../views/activity/activity_2024_05.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202406/:id',
		name: 'activity_2024_06',
		component: () => import('../views/activity/activity_2024_06.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202407/:id',
		name: 'activity_2024_07',
		component: () => import('../views/activity/activity_2024_07.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202408/:id',
		name: 'activity_2024_08',
		component: () => import('../views/activity/activity_2024_08.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity_Qixi',
		name: 'activity_Qixi',
		component: () => import('../views/activity/activity_Qixi.vue'),
		meta: {
			title: '甜蜜七夕积分战',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202409/:id',
		name: 'activity_2024_09',
		component: () => import('../views/activity/activity_2024_09.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202410/:id',
		name: 'activity_2024_10',
		component: () => import('../views/activity/activity_2024_10.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202411/:id',
		name: 'activity_2024_11',
		component: () => import('../views/activity/activity_2024_11.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202412/:id',
		name: 'activity_2024_12',
		component: () => import('../views/activity/activity_2024_12.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity20250101',
		name: 'activity_2025_01_01',
		component: () => import('../views/activity/activity_2025_01_01.vue'),
		meta: {
			title: '2025年度迎新盛典',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202501/:id',
		name: 'activity_2025_01',
		component: () => import('../views/activity/activity_2025_01.vue'),
		meta: {
			title: '',
			keepAlive: false,
			showHeader: false,
		}
	}, {
		path: '/activity202502/:id',
		name: 'activity_2025_02',
		component: () => import('../views/activity/activity_2025_02.vue'),
		meta: {
			title: '2025 2月充值活动',
			keepAlive: false,
			showHeader: false,
		}
	},{
		path: '/activityValentine',
		name: 'activity_valentine',
		component: () => import('../views/activity/activity_valentine.vue'),
		meta: {
			title: '恋韵同心共舞',
			keepAlive: false,
			showHeader: false,
		}
	},
]

const router = new Router({
	// base: '/dist',
	// mode: 'history',
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		} // return的结果, 就是 期望滚动到哪个的位置
	}
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

export default router
